#work div.outcomes {
  border-top: solid 10px var(--pink);
  border-bottom: solid 10px var(--pink);
}

#work div.card {
  background-color: var(--pink);
}

img.lb-image {
  border-color: var(--pink) !important;
}