#header {
  border-bottom: solid 1px var(--pink);
  padding-bottom: 50px;
  margin-bottom: 20px;
}

#header div.nav {
  font-size: 1.2em;
}

#header div.contact {
  font-size: 1.2em;
}

#header div.nav a {
  text-decoration: none;
  color: var(--headerLink);
  font-weight: bolder;
}

#header div.nav a:hover, #header div.nav a.active {
  color: var(--pink);
}

#header div.logo {
  vertical-align: bottom;
}

#header div.mobileNavButton {
  background: var(--headerLink);
  position: fixed;
  top: 5px;
  right: 12px;
  width: 50px;
  z-index: 9999999999;
  text-align: center;
  border-radius: 5px;
}

#header div.mobileContact a {
  width: 50px;
}

#header div.mobileNav {
  background-color: var(--bgColor);
  position: fixed;
  top: -300px;
  transition: 1s;
  left: 0;
  width: 100%;
  z-index: 9999999998;
}

#header div.mobileNav a {
  display: block;
  text-align: center;
  padding: 10px;
  border-bottom: solid 1px var(--pink);
}

#header div.mobileNav.show {
  transition: 1s;
  top: 0;
}