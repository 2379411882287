#footer {
  margin-top: 30px;
}

#footer .banner {
  background: rgb(var(--bs-dark-rgb));
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
}

#footer .banner h5 {
  font-weight: bold;
  font-size: 1.2em;
}

#footer .copyright {
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: bold;
}

#footer img.paymentOptions {
  height: 50px;
}