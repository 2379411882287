#product div.features {
  border-top: solid 10px var(--pink);
  border-bottom: solid 10px var(--pink);
}

#product div.colours {
  border-bottom: solid 10px var(--pink);
}

#product div.pallet div.sample {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-bottom: 5px;
}