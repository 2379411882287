@font-face {
  font-family: Tumbly;
  src: url("https://images.rollettadoors.co.uk/fonts/tumbly.otf") format("opentype");
}

body {
  font-family: Tumbly, Arial, Helvetica, sans-serif;
  color: var(--text);
  font-size: 1.2em;
  background-color: var(--bgColor);
}

:root {
  --bs-dark-rgb: 44, 28, 58;
  --pink: #d96aa5;
  --darker-pink: #974c74;
  --grey: #f0f0f0;
  --text: #2c1c3a;
  --bgColor: #1d1d1b;
  --headerLink: #ffffff;
}

a {
  text-decoration: none;
  color: var(--pink)
}

a:hover {
  color: var(--darker-pink)
}

.telephone, .standard {
  font-family: Arial, Helvetica, sans-serif;
}

.btn-primary {
  background-color: var(--pink);
  border-color: var(--pink);
  color: #ffffff;
}

.btn-primary:hover {
  background-color: var(--darker-pink);
  border-color: var(--pink);
}

@import 'header';
@import 'homepage';
@import 'footer';
@import 'contact';
@import 'work';
@import 'pricing';
@import 'error';
@import 'product';