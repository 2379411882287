#homepage .carousel-caption {
  background: rgb(var(--bs-dark-rgb));
  color: var(--pink);
  width: 100%;
  left: 0;
  right: 0;
}

#homepage .carousel-caption h5 {
  font-weight: bold;
}

#homepage .carousel img {
  width: 100%;
}

#homepage div.about-facebook {
  border-top: solid 10px var(--pink);
  border-bottom: solid 10px var(--pink);
}

#homepage div.testimonials {
  border-top: solid 10px var(--pink);
  background: var(--grey);
}

#homepage div.testimonials div.testimonal {
  min-height: 300px;
}

#homepage #workCarousel {
  border-top: solid 10px var(--pink);
}

#homepage div.about {
  border-top: solid 10px var(--pink);
}