#pricing h5 {
  font-weight: bold;
}

#pricing .price {
  font-size: 1.5em;
}

#pricing div.start {
  border-top: solid 1px var(--pink);
}

#pricing div.end {
  border-top: solid 1px var(--grey);
  border-bottom: solid 1px var(--pink);
}

#pricing div.muted {
  font-size: 0.7em;
}